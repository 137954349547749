import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { getImage } from "gatsby-plugin-image"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import DownloadOs from "../components/Download/DownloadOs"

const DownloadPage = ({ location }) => {

  const { win, mac, meta, macApp, winApp, zoom, teams, skype, meet  } = useStaticQuery(graphql`
  query downloadZhPageQuery {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    win: file(relativePath: {eq: "DL-win.png"}) {
      childImageSharp {
          gatsbyImageData (
              width: 200
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
        }
      }
    mac: file(relativePath: {eq: "DL-mac.png"}) {
      childImageSharp {
        gatsbyImageData (
              width: 200
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
        }
      }
    macApp: file(relativePath: {eq: "ChonkerKeys.pkg"}) {
      publicURL
    }
    winApp: file(relativePath: {eq: "ChonkerKeys.appxbundle"}) {
      publicURL
    }
    zoom: file(relativePath: {eq: "DL-zoom.png"}) {
        childImageSharp {
          gatsbyImageData (
                height: 30
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
          }
        }
    teams: file(relativePath: {eq: "DL-teams.png"}) {
    childImageSharp {
      gatsbyImageData (
            height: 30
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
      }
    }
    skype: file(relativePath: {eq: "DL-skype.png"}) {
        childImageSharp {
          gatsbyImageData (
                height: 30
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
          }
        }
    meet: file(relativePath: {eq: "DL-meet.png"}) {
    childImageSharp {
      gatsbyImageData (
            height: 27
            layout: CONSTRAINED
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
      }
    }
  }`)

  const winImage = getImage(win)
  const macImage = getImage(mac)
  const zoomImage = getImage(zoom)
  const teamsImage = getImage(teams)
  const skypeImage = getImage(skype)
  const meetImage = getImage(meet)

  return (
    <Layout data={meta} location={location}>
      <SiteMetadata
        title="下載創客鍵軟件"
        description="Keypads for video and computing"
      />
      <DownloadOs
        winImage={winImage}
        winApp={winApp.publicURL}
        macImage={macImage}
        macApp={macApp.publicURL}
        zoomImage={zoomImage}
        teamsImage={teamsImage}
        skypeImage={skypeImage}
        meetImage={meetImage}
      />
    </Layout>
  )
}

export default DownloadPage

