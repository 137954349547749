import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"

const DownloadCard = ({ title, description, image, url }) => {
    return (
        <div className="w-[24rem] flex flex-wrap my-6 justify-center">
            <div className="px-6">
                <div>
                    <a href={url} target="_blank" rel="noreferrer">
                        <GatsbyImage className="os-button" width={60} image={image} alt={`ChonkerKeys Download ${title} image`} />
                    </a>
                </div>
                <h1 className="text-center md:text-5xl my-6">{title}</h1>
                <p className="small" >{description}</p>
            </div >
        </div >
    )
};

export default DownloadCard;
