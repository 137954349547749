import React, {useEffect, useRef} from 'react';
import { injectIntl } from 'react-intl';
import DownloadCard from '../DownloadCard';
import { GatsbyImage } from "gatsby-plugin-image";
<style>

</style>

const DownloadOs = ({ winImage, winApp, macImage, macApp, zoomImage, teamsImage, skypeImage, meetImage, intl }) => {
    const translate = messageId => intl.formatMessage({ id: messageId })
    const ref = useRef(null);
    const scrollToElement = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };
    console.log(macApp)
    useEffect(() => {
      scrollToElement();
    }, []);

    return (
        <div className="h-full bg-cklightyellow">
            <div className="container flex flex-col justify-center items-center text-center overflow-hidden py-32 mx-auto" style={{ paddingTop: '96px' }}>
                <h1 className="mb-8 text-5xl" style={{ marginBottom:'0px'}}>{translate('download.title')}</h1>
                <h1 className="max-w-[85%] md:max-w-[60%] my-8">{translate('download.description')}</h1>
                <div ref={ref} style={{display:"hidden", position: "relative", top: "-96px"}}></div>
                <div className="flex flex-col md:flex-row md:justify-center">
                    <DownloadCard title="Windows" description={translate('download.windows.compatibility')} image={winImage} url={winApp} />
                    <DownloadCard title="Mac" description={translate('download.mac.compatibility')} image={macImage} url={macApp} />
                </div>
                <div className="flex flex-col md:flex-row md:justify-center">
                    <div className="w-[24rem] flex-wrap my-2 mx-2 justify-center" style={{backgroundColor:'white', marginBottom: '20px', paddingTop:'20px', paddingLeft:'32px', paddingRight:'20px', paddingBottom:'20px', borderRadius:'40px', boxShadow:'0px 4px 8px 0px rgba(0, 0, 0, 0.2), 0px 6px 20px 0px rgba(0, 0, 0, 0.2)'}}>
                        <h1 className="text-center md:text-2xl my-2" style={{marginLeft:"-13px"}}>What's New in 1.5.0.0</h1>
                        <p className="md:text-xs" style={{marginLeft:"-13px"}}>Last updated 3 Jan 2024</p>
                            <ul className="text-left md:text my-1 mx-2" style={{color:"rgb(45 55 72)"}}>
                                <li className="md:text-lg" style={{fontWeight: 'bold'}}>New Feature</li>
                                <li className="md:text-sm"><span style={{marginLeft: "-27px", marginRight: "5px"}}>⭐️</span>New custom icons in the Rearrange button screen for selecting the bonus keycaps!!</li>
                            </ul>
                    </div>
                    <div className="w-[24rem] flex-wrap my-2 mx-2 justify-center" style={{backgroundColor:'white', marginBottom: '20px', paddingTop:'20px', paddingLeft:'32px', paddingRight:'20px', paddingBottom:'20px', borderRadius:'40px', boxShadow:'0px 4px 8px 0px rgba(0, 0, 0, 0.2), 0px 6px 20px 0px rgba(0, 0, 0, 0.2)'}}>
                        <h1 className="text-center md:text-2xl my-2" style={{marginLeft:"-13px"}}>What's New in 1.5.00</h1>
                        <p className="md:text-xs" style={{marginLeft:"-13px"}}>Last updated 3 Jan 2024</p>
                            <ul className="text-left md:text my-1 mx-2" style={{color:"rgb(45 55 72)"}}>
                                <li className="md:text-lg" style={{fontWeight: 'bold'}}>New Feature</li>
                                <li className="md:text-sm"><span style={{marginLeft: "-27px", marginRight: "5px"}}>⭐️</span>New custom icons in the Rearrange button screen for selecting the bonus keycaps!!</li>
                            </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default injectIntl(DownloadOs);
